<template>
  <div :class="{ disabled: disabled }">
    <span>{{ text }}</span>
    <v-btn
      v-if="canInvite"
      depressed
      tile
      outlined
      color="primary"
      :disabled="disabled"
      class="ml-3"
      small
      @click="handleInvite"
    >
      {{ $t('contract.actions.invite') }}
    </v-btn>
    <v-btn
      v-if="canReInvite"
      :disabled="disabled"
      depressed
      tile
      outlined
      color="primary"
      class="ml-3"
      small
      @click="handleRemind"
    >
      {{ $t('contract.actions.reInvite') }}
    </v-btn>
  </div>
</template>

<script>
import { signableStatus } from '@/application/enums/signableStatus.js';
import { requestSigning, sendSigningReminder } from '@/modules/contract/api';
import eventBus from '@/application/eventBus.ts';
import dayjs from '@/plugins/dayjs';
import { contractStatusType } from '@/application/enums/contractStatusType.ts';

export default {
  name: 'SignableStatus',
  props: {
    signableStatusId: {
      required: true,
    },
    signableStatusUpdatedAt: {
      required: true,
    },
    contractId: {
      required: true,
    },
    contractStatusId: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text() {
      const key = Object.keys(signableStatus).find(key => signableStatus[key] === this.signableStatusId);
      return this.$t(`contract.signableStatus.${key}`, { date: dayjs(this.signableStatusUpdatedAt).format('LLL') });
    },
    canInvite() {
      return this.contractStatusId !== contractStatusType.EXPIRED && this.contractStatusId !== contractStatusType.TERMINATED && this.signableStatusId === null;
    },
    canReInvite() {
      return this.contractStatusId !== contractStatusType.EXPIRED && this.contractStatusId !== contractStatusType.TERMINATED && this.signableStatusId === signableStatus.INVITED;
    },
  },
  methods: {
    handleInvite() {
      this.handleInviteCall(requestSigning);
    },
    handleRemind() {
      this.handleInviteCall(sendSigningReminder);
    },
    async handleInviteCall(apiCall) {
      try {
        await apiCall(this.contractId);
        this.$emit('change');
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('contract.messages.signSuccess'),
        });
      } catch (e) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
      }
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.6;
}
</style>
