<template>
  <div id="contract-index">
    <KCrudLayout :with-search="false">
      <template #header>
        {{ $tc('contract.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('contract.title', 1) }) }}
        </v-btn>
        <ContractForm
          ref="createContractForm"
          v-model="createDialog"
          :candidate-id="candidate.id"
          :request="createRequest"
          :title="$tc('contract.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          language-prefix="contract.fields"
          :resource="resource"
          @click:edit="openUpdate"
        >
          <template #item.isActive="{ item }">
            <v-icon
              v-if="contractIsActive(item.contractStatusId)"
              color="success"
            >
              $circle
            </v-icon>
          </template>
          <template #item.contractStatusId="{ item }">
            {{ $t(`contract.contractStatusTypes.${getEnumKeyByValue(contractStatusType, item.contractStatusId)}`) }}
          </template>
          <template #item.contractTypeId="{ item }">
            {{ $t(`contract.contractTypes.${item.contractTypeId}`) }}
          </template>
          <template #item.startDate="{ item }">
            {{ dayjs(item.startDate, 'YYYY-MM-DD').format('LL') }}
          </template>
          <template #item.endDate="{ item }">
            <span v-if="item.endDate">
              {{ dayjs(item.endDate, 'YYYY-MM-DD').format('LL') }}
            </span>
            <span v-else>{{ $t('contract.indefinitely') }}</span>
          </template>
          <template #item.weeksWorked="{ item }">
            <span>
              {{ item.weeksWorked }}
            </span>
          </template>
          <template #item.signableStatusId="{ item }">
            <v-tooltip
              top
              :disabled="item.initialPlacementId !== null"
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <SignableStatus
                    :signable-status-id="item.signableStatusId"
                    :signable-status-updated-at="item.signableStatusUpdatedAt"
                    :contract-id="item.id"
                    :contract-status-id="item.contractStatusId"
                    :disabled="item.initialPlacementId === null"
                    @change="$refs.table.reload()"
                  />
                </div>
              </template>
              <span>{{ $t('contract.messages.noPlacement') }}</span>
            </v-tooltip>
          </template>
          <template #actions="{ item }">
            <v-btn
              color="red"
              icon
              :disabled="!canDelete(item)"
              @click="deleteContract(item)"
            >
              <v-icon>$delete</v-icon>
            </v-btn>
            <v-btn
              color="text--secondary"
              icon
              @click="downloadContract(item)"
            >
              <v-icon>$filePdf</v-icon>
            </v-btn>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <ContractForm
      ref="updateContractForm"
      v-model="updateDialog"
      :candidate-id="candidate.id"
      :request="updateRequest"
      :title="$tc('contract.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import { contract } from '@/application/enums/contract'; //@TODO convert into separate enum objects
import eventBus from '@/application/eventBus.ts';
import Contract from '@/application/models/Contract.js';
import downloadFile from '@/application/util/downloadFile.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, fetchPdf, index, show, update, deleteContract } from '@/modules/contract/api';
import ContractForm from '@/modules/contract/components/ContractForm.vue';
import dayjs from '@/plugins/dayjs';
import { mapGetters } from 'vuex';
import SignableStatus from '@/modules/contract/components/SignableStatus.vue';
import { getEnumKeyByValue } from '@/application/helpers/enum.js';
import { contractStatusType } from '@/application/enums/contractStatusType.ts';

export default {
  name: 'ContractTable',
  components: {
    SignableStatus,
    KCrudTable,
    KCrudLayout,
    ContractForm,
  },
  props: {
    candidate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contract,
      resource: 'contract',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Contract(),
      createFormValues: new Contract(),
    };
  },
  computed: {
    contractStatusType: () => contractStatusType,
    ...mapGetters({ can: 'authorisation/can' }),
    crudHeaders() {
      return [
        {
          text: '',
          value: 'isActive',
          align: 'center',
          sortable: false,
          filterable: false,
          groupable: false,
          width: '1%',
          cellClass: 'no-table-separator',
        }, {
          value: 'contractStatusId',
          language: 'contract.crudHeaders.status',
          cellClass: 'no-table-separator',
        }, {
          value: 'contractTypeId',
          language: 'contract.crudHeaders.type',
        }, {
          value: 'startDate',
          language: 'contract.crudHeaders.dateStart',
        }, {
          value: 'endDate',
          language: 'contract.crudHeaders.dateEnd',
        }, {
          value: 'weeksWorked',
          language: 'contract.crudHeaders.weeksWorked',
          align: 'end',
        }, {
          value: 'signableStatusId',
          language: 'contract.crudHeaders.signableStatusId',
        },
      ];
    },
    candidateId() {
      return this.candidate.id;
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      },
      {
        exact: true,
        to: { name: 'candidate.contract' },
        text: this.$tc('contract.title', 2),
      },
    ]);
  },
  methods: {
    getEnumKeyByValue: getEnumKeyByValue,
    canDelete(contract) {
      return (contract.contractStatusId === contractStatusType.CONCEPT || contract.contractStatusId === contractStatusType.SENT) && contract.isDeletable;
    },
    dayjs,
    handleOpenCreateDialog() {
      this.$refs.createContractForm.conflictedStartDate = null;
      this.$refs.createContractForm.conflictedEndDate = null;
      this.createFormValues = new Contract();
      this.createDialog = true;
    },
    indexRequest() {
      return index(this.candidateId, ...arguments);
    },
    createRequest() {
      return create(this.candidateId, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    contractIsActive(contractStatusId) {
      return contractStatusId === contractStatusType.ACTIVE;
    },
    async openUpdate(item) {
      this.$refs.updateContractForm.conflictedStartDate = null;
      this.$refs.updateContractForm.conflictedEndDate = null;
      this.updateFormValues = new Contract();
      const { data: { data } } = await show(item.id);
      this.updateFormValues.mapResponse(data);
      this.updateDialog = true;
    },
    async downloadContract(item) {
      try {
        await downloadFile(fetchPdf(item.id), `${item.contractTemplateName}_${this.candidate.name}_${item.startDate}`);
      } catch (error) {
        console.warn(error);
      }
    },
    async deleteContract(item) {
      try {
        eventBus.$emit('confirm', {
          title: this.$t('actions.deleteConfirmation.title'),
          body: this.$t('actions.deleteConfirmation.body', { resource: this.$tc('contract.title', 1) }),
          confirmCallback: async () => {
            await deleteContract(item.id);
            this.$refs.table.reload();
          },
        });
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>
